const DigitalMemoria = () => {
    return <>
    <div className="project_page page_container">

    <video src = "Projects/DigitalMemoria/Memoria_02.mp4" autoPlay muted loop style={{width: "100%"}} > </video>

    <div class = "project_caption">
        <div className="project_description">
            <div className="big_titles">
                Digital Memoria
            </div>
            <div className="med_caption">
                A WIP project designed for Parsons. It is a digital repository of my memories. <a href = "https://nikitash98.github.io/digital_memories/">https://nikitash98.github.io/digital_memories/</a>
            </div>
            </div>
            <div className="two_col">
                <div>
                    <h1></h1>
                    <p>
                        This is a WIP project, designed for Major Studio at Parsons. It is meant to investigate digital memory and photogrammetry, through the lens
                        of personal memory. Some of the scans are created using iPhone photogrammetry techniques. Others are generated from photography and using manual
                        sculpting techniques. This is all presented on the web using React Three Fiber.
                        <br/>
                        <br/> The project had to be abandoned due to scheduling. 
                    </p>
                </div>
                <div class = "project_details">
                    <div className="types_container">
                        <div className="med_caption">
                            Fields:
                        </div>
                        <div className="interests">
                            3D
                        </div>
                        <div className="interests">
                            Web
                        </div>

                    </div>
                    <div className="tools_container">
                        <div className="med_caption">
                            Tools:
                        </div>
                        <div className="interests">
                            Zbrush
                        </div>
                        <div className="interests">
                            Maya
                        </div>
                        <div className="interests">
                            React Three Fiber
                        </div>
                        <div className="interests">
                            Photoshop
                        </div>
                        <div className="interests">
                            Photogrammetry
                        </div>
                        <div className="interests">
                            ReactJS
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <video src = "Projects/DigitalMemoria/Memoria_01.mp4" autoPlay muted loop style={{width: "100%"}} > </video>

        <div className = "two_col">
            <img src = "Projects/DigitalMemoria/Scan_01.jpg"/>
            <img src = "Projects/DigitalMemoria/Scan_02.jpg"/>

        </div>
    </div>


    
    
    </>
}


export default DigitalMemoria