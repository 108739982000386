const SketchNight = () => {
    return <>
    <div className="project_page page_container">

    <div className="two_col">
        <img src="Projects/SketchNight/Sketch_Night_01.jpg" style={{margin: "auto"}}/>
        <img src="Projects/SketchNight/Sketch_Night_02.jpg" style={{margin: "auto"}} />
    </div>

    <div class = "project_caption">
        <div className="project_description">
            <div className="big_titles">
                Sketch Night at the Society
            </div>
            <div className="med_caption">
                A concept for a <a href="https://nikitash98.github.io/illustrators/">website </a> and poster set for the Sketch Night at the Society of Illustrators
            </div>
            </div>
            <div className="two_col">
                <div>
                    <p>
                        This was a prototype website and poster set created for a class at Parsons. The design uses 3D scans of bodies in an impressionistic, blurred way. <br/>
                        <br/>
                        The website is powered by React Three Fiber, and uses an innovative rack focus technique, based on ray distance from the camera.
                    </p>
                </div>
                <div class = "project_details">
                    <div className="types_container">
                        <div className="med_caption">
                            Fields:
                        </div>
                        <div className="interests">
                            3D
                        </div>
                        <div className="interests">
                            Web
                        </div>

                    </div>
                    <div className="tools_container">
                        <div className="med_caption">
                            Tools:
                        </div>
                        <div className="interests">
                            Zbrush
                        </div>
                        <div className="interests">
                            Blender
                        </div>
                        <div className="interests">
                            Cycles
                        </div>
                        <div className="interests">
                            React Three Fiber
                        </div>
                        <div className="interests">
                            Photoshop
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <img src="Projects/SketchNight/sketch_website.jpg"/>
        <img src="Projects/SketchNight/sketch_website_02.jpg"/>
        <div className="large_caption">
            A caption here
        </div>
        <div className="two_col">
            <img src="Projects/SketchNight/Hand_Blender_01.jpg"/>
            <img src="Projects/SketchNight/Hand_Blender_02.jpg"/>
        </div>

    </div>


    
    
    </>
}


export default SketchNight