import "./Footer.css"

const Footer = () => {
    return <div className="footer_container">
        <span id = "copyright"> © 2023 Nikita Shtarkman. All Rights Reserved. </span>
        <div className="footer_right">
        
        
        <a href="mailto:nikitash98@gmail.com" className="footer_links" target="_blank">
            <span id = "copyright">email</span>
        </a>

        <a href="https://www.instagram.com/nikitashtrk/" className="footer_links"  target="_blank">
            <span id = "copyright">instagram</span>
        </a>

        <a href="https://www.linkedin.com/in/nikita-shtarkman-78b449154/" className="footer_links"  target="_blank">
            <span id = "copyright">LinkedIn</span>
        </a>
        </div>

    </div>
}

export default Footer