const DragonAsh = () => {
    return <>
    <div className="project_page page_container">
    <div style = {{backgroundColor: "black"}}>
    <img src="Projects/DragonAsh/dragon_ash_01.jpg" />

    </div>

    <div class = "project_caption">
        <div className="project_description">
            <div className="big_titles">
                Dragon Ash
            </div>
            <div className="med_caption">
                A texturing and modeling challenge - based on a fantastic benchpillow concept.
            </div>
            </div>
            <div className="two_col">
                <div>
                    <p>
                    A semi-stylized blaster based on a fantastic benchpillow concept.
                    <br/>
                    <br/>

The goal here was to create something that existed in the space between realism. The sculpt itself is quite simple - but on the last pass a smattering of dents were thrown on to give it some character. The materials are designed with an edge wear that doubles as a pen-stroke effect. Ambient occulsion is baked into the texture to exaggerate depth.



                    </p>
                </div>
                <div class = "project_details">
                    <div className="types_container">
                        <div className="med_caption">
                            Type:
                        </div>
                        <div className="interests">
                            3D
                        </div>
                    </div>
                    <div className="tools_container">
                        <div className="med_caption">
                            Tools:
                        </div>
                        <div className="interests">
                            Zbrush
                        </div>
                        <div className="interests">
                            Maya
                        </div>
                        <div className="interests">
                            Arnold
                        </div>
                        <div className="interests">
                            Substance Painter
                        </div>
                        <div className="interests">
                            Photoshop
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="two_col">
        <img src="Projects/DragonAsh/dragon_ash_02.jpg"/>
        <img src="Projects/DragonAsh/dragon_ash_03.jpg"/>

        </div>

        <div className="two_col">

<div className = "large_caption">
    Beautiful concept by BenchPillow - found <a href = "https://www.instagram.com/p/B3nbUCxC_1W/">here. </a>
</div>
<img src="Projects/DragonAsh/dragonconcept.png"/>

</div>
        <img src="Projects/DragonAsh/clay.png"/>
        <img src="Projects/DragonAsh/uv.png"/>
        <img src="Projects/DragonAsh/uvs.png"/>




    </div>


    
    
    </>
}


export default DragonAsh