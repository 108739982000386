import logo from './logo.svg';
import './App.css';
import About from './About'
import { Route, Routes, Link, HashRouter } from "react-router-dom";
import HomePage from './HomePage';
import Navbar from './Navbar';
import Footer from './Footer';
import Bear from './Projects/Bear';
import Ecorche from './Projects/Ecorche';
import MapOfTheUniverse from './Projects/MapOfTheUniverse';
import DragonAsh from './Projects/DragonAsh';
import ParsonsFestival from './Projects/ParsonsFestival';
import Fragmentary from './Projects/Fragmentary';
import Louis from './Projects/Louis';
import SketchNight from './Projects/SketchNight';
import Nike from './Projects/Nike';
import Quantum from './Projects/Quantum';
import DigitalMemoria from './Projects/DigitalMemoria';
import Revolve from './Projects/Revolve';

import ScrollToTop from './ScrollToTop';
function App() {
  return (
    <div className="App">

    <HashRouter>
    <ScrollToTop/>
    <Navbar/>
      <Routes>
        <Route exact path="/" element = {<HomePage/>}/>
        <Route path = "/about" element={<About/>}/>
        {/* PROJECT ROUTES */}
        <Route path = "/projects/bear" element = {<Bear/>}/>
        <Route path = "/projects/ecorche" element = {<Ecorche/>}/>
        <Route path = "/projects/map_of_universe" element = {<MapOfTheUniverse/>}/>
        <Route path = "/projects/dragon_ash" element = {<DragonAsh/>}/>
        <Route path = "/projects/parsonsfestival" element = {<ParsonsFestival/>}/>
        <Route path = "/projects/fragmentary" element = {<Fragmentary/>}/>
        <Route path = "/projects/louis" element = {<Louis/>}/>
        <Route path = "/projects/sketchnight" element = {<SketchNight/>}/>
        <Route path = "/projects/nike" element = {<Nike/>}/>
        <Route path = "/projects/quantum" element = {<Quantum/>}/>
        <Route path = "/projects/digitalmemoria" element = {<DigitalMemoria/>}/>
        <Route path = "/projects/revolveconsist" element = {<Revolve/>}/>

      </Routes>

    <Footer/>
    </HashRouter>
    </div>


  );
}

export default App;
