const Nike = () => {
    return <>
    <div className="project_page page_container">

    <video src = "Projects/Nike/Shoes.MP4" autoPlay muted loop style={{width: "100%"}}  > </video>

    <div class = "project_caption">
        <div className="project_description">
            <div className="big_titles">
                Nike Off-White Blazers 
            </div>
            <div className="med_caption">
                Custom Nike Off-White Blazers created as a study of materials and procedural modeling.
            </div>
            </div>
            <div className="two_col">
                <div>
                    <p>
                        These Nikes were created in Zbrush, and textured in Substance Painter. This was a study in how to best
                        create footwear, using procedural tools in Zbrush. The shoe was picked due to its variety of high frequency
                        details.
                    </p>
                </div>
                <div class = "project_details">
                    <div className="types_container">
                        <div className="med_caption">
                            Type:
                        </div>
                        <div className="interests">
                            3D
                        </div>
                    </div>
                    <div className="tools_container">
                        <div className="med_caption">
                            Tools:
                        </div>
                        <div className="interests">
                            Zbrush
                        </div>
                        <div className="interests">
                            Maya
                        </div>
                        <div className="interests">
                            Arnold
                        </div>
                        <div className="interests">
                            Substance Painter
                        </div>
                        <div className="interests">
                            Photoshop
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="two_col">
            <img src="Projects/Nike/one.jpg" style={{width: "100%"}}/>
            <img src="Projects/Nike/two.jpg" style={{width: "100%"}}/>
        </div>

    <img src="Projects/Nike/clay.jpg"/>
    <img src="Projects/Nike/wire.jpg"/>
    <img src="Projects/Nike/colors.jpg"/>



    </div>


    
    
    </>
}


export default Nike