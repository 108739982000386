const Louis = () => {
    return <>
    <div className="project_page page_container">
    <img src="Projects/Louis/unreal.png"/>


    <div class = "project_caption">
        <div className="project_description">
            <div className="big_titles">
                Louis Vuitton X Kanye Jaspers 
            </div>
            <div className="med_caption">
                Freelance project for a client, an exploration in low poly, game-res realism.
            </div>
            </div>
            <div className="two_col">
                <div>
                    <p>
                        Creating a custom pair of sneakers for a client. These are high end luxury sneakers with a variety of small details and interesting texture patterns.
                        <br/>
                        <br/>
                        The project had a low polycount, and there was much optimization and baking to make this work.
                    </p>
                </div>
                <div class = "project_details">
                    <div className="types_container">
                        <div className="med_caption">
                            Type:
                        </div>
                        <div className="interests">
                            3D
                        </div>
                    </div>
                    <div className="tools_container">
                        <div className="med_caption">
                            Tools:
                        </div>
                        <div className="interests">
                            Zbrush
                        </div>
                        <div className="interests">
                            Maya
                        </div>
                        <div className="interests">
                            Arnold
                        </div>
                        <div className="interests">
                            Substance Painter
                        </div>
                        <div className="interests">
                            Photoshop
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img src="Projects/Louis/close.png"/>
        <img src="Projects/Louis/HighresScreenshot00002.png"/>
        <div className = "two_col">
            <img src="Projects/Louis/one angle2.png"/>
            <img src="Projects/Louis/angleother.png"/>

        </div>

        <div class="sketchfab-embed-wrapper"> <iframe style ={{"width": "100%", "height": "1000px"}}title="ZDFRS" frameborder="0" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share src="https://sketchfab.com/models/c6299633c4bb4ba097e7910b94fa9d89/embed"> </iframe> </div>

        <div className = "two_col">
        <div className = "large_caption">
                I created a poster using the finalized assets, for presentation and personal practice.
            </div>

            <img src="Projects/Louis/cover.png" />
        </div>

    </div>


    
    
    </>
}


export default Louis