const Revolve = () => {
    return <>
    <div className="project_page page_container">


    <div className="two_col" style={{backgroundColor: "black"}}>
        <video src = "Projects/Revolve/Revolve.mp4" autoPlay muted loop controls  style={{margin: "auto"}}  > </video>
        <video src = "Projects/Revolve/Consist.mp4" autoPlay muted loop controls  style={{margin: "auto"}}  > </video>

    </div>

    <div class = "project_caption">
        <div className="project_description">
            <div className="big_titles">
                Revolve | Consist
            </div>
            <div className="med_caption">
                A soundscape animation with a typographic overlay.
            </div>
            </div>
            <div className="two_col">
                <div>
                    <p>
                    A skeleton sculpted in Zbrush as a study of human anatomy. This skeleton was then ripped apart, blown up, pulled and distroted in an abstract video series. The immersive sound in the videos is courtesy of Anton Shtarkman.
                    <br/>
                    <br/>
                        The skeleton was sculpted using a variety of reference material to be an anatomically accurate reproduction.
                    </p>
                </div>
                <div class = "project_details">
                    <div className="types_container">
                        <div className="med_caption">
                            Fields:
                        </div>
                        <div className="interests">
                            3D
                        </div>
                        <div className="interests">
                            Motion
                        </div>

                    </div>
                    <div className="tools_container">
                        <div className="med_caption">
                            Tools:
                        </div>
                        <div className="interests">
                            Zbrush
                        </div>
                        <div className="interests">
                            Maya
                        </div>
                        <div className="interests">
                            Arnold
                        </div>
                        <div className="interests">
                            Substance Painter
                        </div>
                        <div className="interests">
                            Photoshop
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="three_col" style={{backgroundColor: "black"}}>
            <img src = "Projects/Revolve/skele1.png" />

            <img src = "Projects/Revolve/skele3.png"/>

            <img src = "Projects/Revolve/skele2.png"/>
        </div>


        <div style={{backgroundColor: "black"}}>

        <img src = "Projects/Revolve/closeskele.png"/>


        <img src = "Projects/Revolve/finalfeet.png"/>

        <img src = "Projects/Revolve/finalhand.png"/>

        <video src = "Projects/Revolve/break.mp4" autoPlay muted loop style={{width: "100%"}}  > </video>
        <video src = "Projects/Revolve/spin.mp4" autoPlay muted loop style={{width: "100%"}}  > </video>

        </div>

    </div>

    
    
    </>
}


export default Revolve