const MapOfTheUniverse = () => {
    return <>
        <div className="project_page page_container">

        <img src = "Projects/Map_Of_Universe/mainmap.jpg"/>
        <div class = "project_caption">
            <div className="project_description">

            <div className="big_titles">
                Map of the Universe
            </div>
            <div className="med_caption">
                Map of 200,000 universes displayed on a poster. View the custom website here: <a href = "https://mapoftheuniverse.net/">https://mapoftheuniverse.net/ </a>
            </div>
            </div>
            <div className="two_col">
                <div>
                    <p>
                    On your screen are hundreds of thousands of galaxies, showing a slice of the universe up to the Big Bang.
                    <br/>

                    This was a project created with <a href = "https://menard.pha.jhu.edu/">Dr. Brice Menard</a> , an astrophysicist at Johns Hopkins University. This was a several year project. First, a variety of cosmic datasets were parsed, plotted, and combined. Using simply python plotting libraries, we created hundreds of different visualizations, from polar-plots, 
                    to cartesian transformations, in order to find the best view of the universe. 
                    <br/>
                    <br/>
                    These visualizations were then converted in posters, and finally, we designed and coded a fully custom website which smoothly displays the different views. It also allows for a scrollable experience of the banner view.
                    </p>
                </div>
                <div class = "project_details">
                    <div className="types_container">
                        <div className="med_caption">
                            Fields:
                        </div>
                        <div className="interests">
                            Web
                        </div>
                        <div className="interests">
                            Design
                        </div>

                    </div>
                    <div className="tools_container">
                        <div className="med_caption">
                            Tools:
                        </div>
                        <div className="interests">
                            Python
                        </div>
                        <div className="interests">
                            Matplotlib
                        </div>
                        <div className="interests">
                            JQuery
                        </div>
                        <div className="interests">
                            Illustrator
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <img src = "Projects/Map_Of_Universe/horizontal.jpg"/>

        <div className="two_col">
            <img style={{width: "100%"}} src = "Projects/Map_Of_Universe/QUASARS.jpg"/>
            <img style={{width: "100%"}} src = "Projects/Map_Of_Universe/MIRO.jpg"/>
        </div>

        <div className="two_col">
            <img style={{width: "100%"}} src = "Projects/Map_Of_Universe/Deep_Space_02.jpg"/>
            <img style={{width: "100%"}} src = "Projects/Map_Of_Universe/Web_VIB.jpg"/>
        </div>

        <div className="two_col">
        <video src = "Projects/Map_Of_Universe/3d_slice.mp4" autoPlay muted loop width="100%" > </video>
        <div className="large_caption">
            This 3D slice was created by plotting an animation of frames, composited in AfterEffects. 
        </div>

        </div>
        </div>
        
        </>

        
    
}


export default MapOfTheUniverse