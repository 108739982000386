const ParsonsFestival = () => {
    return <>
    <div className="project_page page_container">

    <video src = "Projects/ParsonsFestival/Parsons_Festival_Early.mp4" style={{width: "100%"}} autoPlay muted loop  > </video>

    <div class = "project_caption">
        <div className="project_description">
            <div className="big_titles">
                Parsons Festival
            </div>
            <div className="med_caption">
                Designs created in Lucille Tenazas studio for advertisement and collateral
            </div>
            </div>
            <div className="two_col">
                <div>
                    <p>
                        These are selected designs from a semester working at Lucille Tenazas University Design Studio. My logo
                        was selected as one of the inspirations for the final festival. I then helped create thumbnails and motion graphics,
                        which were used on the website and email.
                    </p>
                </div>
                <div class = "project_details">
                    <div className="types_container">
                        <div className="med_caption">
                            Type:
                        </div>
                        <div className="interests">
                            Design
                        </div>
                        <div className="interests">
                            Motion
                        </div>

                    </div>
                    <div className="tools_container">
                        <div className="med_caption">
                            Tools:
                        </div>
                        <div className="interests">
                            AfterEffects
                        </div>
                        <div className="interests">
                            Blender
                        </div>
                        <div className="interests">
                            Illustrator
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="two_col">
            <img src="Projects/ParsonsFestival/Nikita_Thumb_01.jpg"/>
            <img src="Projects/ParsonsFestival/Nikita_Thumb_02.jpg"/>
        </div>


        <div className="two_col">
        <video src = "Projects/ParsonsFestival/Curtains_Original.mp4" autoPlay muted loop style={{width: "100%"}} > </video>
        <video src = "Projects/ParsonsFestival/Parsons_Festival_02.mp4" autoPlay muted loop  style={{width: "100%"}}> </video>
        </div>
        <div className="two_col">
            <video src = "Projects/ParsonsFestival/Original_Animation.mp4" style={{width: "100%"}} autoPlay muted loop  > </video>
            <video src = "Projects/ParsonsFestival/Parsons_Festival.mp4" style={{width: "100%"}} autoPlay muted loop > </video>

        </div>

    </div>


    
    
    </>
}


export default ParsonsFestival