const Fragmentary = () => {
    return <>
    <div className="project_page page_container">

    <img src="Projects/Fragmentary/Fragmentary_Head.png"/>

    <div class = "project_caption">
        <div className="project_description">
            <div className="big_titles">
                New Fragmentary Things
            </div>
            <div className="med_caption">
                A design and procedural modeling project playing off the mid-Covid NFT craze.
            </div>
            </div>
            <div className="two_col">
                <div>
                    <p>
                    What does it mean to own a piece of art?
                    <br/>
                    <br/>
I developed a 3D pixelization process in Houdini using 3D noises and volume calculations. This is a process that allows for gradation in the pixelization, creating a unique animated effect. I ran that on a variety of recognizable sculptures, and then paired them with philosophical fragments from Friedrich Schlegel's Philosophical Fragments. This was designed into a deck of cards and printable posters.
                    </p>
                </div>
                <div class = "project_details">
                    <div className="types_container">
                        <div className="med_caption">
                            Fields:
                        </div>
                        <div className="interests">
                            3D
                        </div>
                    </div>
                    <div className="tools_container">
                        <div className="med_caption">
                            Tools:
                        </div>
                        <div className="interests">
                            Zbrush
                        </div>
                        <div className="interests">
                            Maya
                        </div>
                        <div className="interests">
                            Arnold
                        </div>
                        <div className="interests">
                            Substance Painter
                        </div>
                        <div className="interests">
                            Photoshop
                        </div>
                        <div className="interests">
                            Illustrator
                        </div>
                        <div className="interests">
                            Houdini
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <video src = "Projects/Fragmentary/fixedsim.mp4" autoPlay muted loop style={{width: "100%"}} > </video>
        <img src="Projects/Fragmentary/cards.png" style={{width: "100%", height: "auto", maxHeight: "100%"}}/>
        <img src="Projects/Fragmentary/Render_Passes.png"/>

        <div className="two_col">
            <img src="Projects/Fragmentary/cards2.jpg"/>
            <img src="Projects/Fragmentary/posters.jpg"/>
        </div>


        <div className="two_col">
            <img src="Projects/Fragmentary/Houdini2.jpg"/>
            <img src="Projects/Fragmentary/Houdini.jpg"/>
        </div>


    </div>


    
    
    </>
}


export default Fragmentary