const Ecorche = () => {
    return <>
    <div className="project_page page_container">
    <div className="two_col">
            <img src = "Projects/Ecorche/Bloke.png"/>

            <img src = "Projects\Ecorche\anatomy2 finish.png"/>
        </div>

        <div class = "project_caption">
            <div className="project_description">
            <div className="big_titles">
                Ecorche Study & Poster
            </div>
            <div className="med_caption">
                A character and shader study that won the Polycount Quarterly Character Art challenge.
            </div>
            </div>
            
            <div className="two_col">
                <div>
                    <p>
                    A poster of one of my anatomy studies. I wanted to inject some humor into a usually dreary subject.
                    <br/>
                    <br/>
                    I sculpted the ecorche in Zbrush as a full study of the human figure. I then posed and rendered it. Finally, I brought it into Illustrator and designed the poster around it.
                    </p>
                </div>
                <div class = "project_details">
                    <div className="types_container">
                        <div className="med_caption">
                            Type:
                        </div>
                        <div className="interests">
                            3D
                        </div>
                    </div>
                    <div className="tools_container">
                        <div className="med_caption">
                            Tools:
                        </div>
                        <div className="interests">
                            Zbrush
                        </div>
                        <div className="interests">
                            Maya
                        </div>
                        <div className="interests">
                            Arnold
                        </div>
                        <div className="interests">
                            Substance Painter
                        </div>
                        <div className="interests">
                            Photoshop
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img src = "Projects\Ecorche\anatomy1finsih.png"/>

        <div className="two_col">
            <video src = "Projects/Ecorche/one.mp4" autoPlay muted loop width="100%" > </video>
            <video src = "Projects/Ecorche/two.mp4" autoPlay muted loop width="100%" > </video>
        </div>
        <img src = "Projects\Ecorche\ecorche.png"/>

        </div>
        </>
    
}


export default Ecorche