const Bear = () => {
    return <>
    <div className="project_page page_container">

        <div className="two_col" style = {{backgroundColor: "black"}}>
        <img src = "Projects/Bear/bearangle1.jpg"/>
        <img src = "Projects/Bear/bearangle2.jpg"/>

        </div>

        <div class = "project_caption">
            <div className="project_description">
            <div className="big_titles">
                Mr. Pop - Polycount Challenge
            </div>
            <div className="med_caption">
                A character and shader study that won the Polycount Quarterly Character Art challenge.
            </div>
            </div>
            
            <div className="two_col">
                <div>
                    <p>
                        This was a project entered into the Quarterly Polycount Character Art challenge.
                        I decided to try to match the surrealism of the concept with a realistic rendering finish - to try and find
                        a nice middle point between the two styles. <br/>
                        Most of the work was designing the shaders - using lots of procedural noises and subsurface scattering to differentiate
                        between the different candy layers.<br/> <br/>
                        An important thing I learned on this project was using MASH in Maya to autogenerate beautiful sugar crystals on the
                        suspenders and the tie.
                    </p>
                </div>
                <div class = "project_details">
                    <div className="types_container">
                        <div className="med_caption">
                            Type:
                        </div>
                        <div className="interests">
                            3D
                        </div>
                    </div>
                    <div className="tools_container">
                        <div className="med_caption">
                            Tools:
                        </div>
                        <div className="interests">
                            Zbrush
                        </div>
                        <div className="interests">
                            Maya
                        </div>
                        <div className="interests">
                            Arnold
                        </div>
                        <div className="interests">
                            Substance Painter
                        </div>
                        <div className="interests">
                            Photoshop
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img src="Projects/Bear/bearwires.jpg"/>

        <img src="Projects/Bear/bearclay.jpg"/>
        <div className = "two_col">
            <div className = "large_caption">
                The concept was beautifully designed by Gaston S. Garcia. <a href = "https://gasdrawn.artstation.com/projects/aKWy2?album_id=1003672"> Click here to view.</a>

            </div>
        <img src="Projects/Bear/gaston.jpeg"/>

        </div>

    </div>
        
        </>

        
    
}


export default Bear