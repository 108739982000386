const Quantum = () => {
    return <>
    <div className="project_page page_container">

    <video src = "Projects/Quantum/nerf.mp4" autoPlay muted loop style={{width: "100%"}} > </video>

    <div class = "project_caption">
        <div className="project_description">
            <div className="big_titles">
                Quantum Jam: N.E.R.F

            </div>
            <div className="med_caption">
                Using NERF technology as a visualizer for a music generator entered into the IBM Quantum Jam
            </div>
            </div>
            <div className="two_col">
                <div>
                    <p>
                    My contribution on a team for the IBM + Touchdesigner Quantum Design Jam challenge.
                    <br/>
                    <br/>
I used the NERF process, and set up NVIDIA's codebase to generate ephemeral, painterly scenes. The concept was to use quantum information to allow the computer to decide whether to display. I used both rendered video from within the NERF application, and the NERF 3D information to create a novel way to transition between scenes.

                    </p>
                </div>
                <div class = "project_details">
                    <div className="types_container">
                        <div className="med_caption">
                            Type:
                        </div>
                        <div className="interests">
                            3D
                        </div>
                    </div>
                    <div className="tools_container">
                        <div className="med_caption">
                            Tools:
                        </div>
                        <div className="interests">
                            Zbrush
                        </div>
                        <div className="interests">
                            Maya
                        </div>
                        <div className="interests">
                            Arnold
                        </div>
                        <div className="interests">
                            Substance Painter
                        </div>
                        <div className="interests">
                            Photoshop
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <video src = "Projects/Quantum/adam_born_trimmed.mp4" autoPlay muted loop style={{width: "100%"}}  > </video>

        <div className="large_caption">
                The birth of adam through the NERF
            </div>
        <div className = "two_col">
        <iframe width="76%" height="500px" src="https://www.youtube.com/embed/K9BKknUSlSM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <div className="large_caption">
                My contributions, as well as the team's work is shown in this video.
            </div>

        </div>

        <video src = "Projects/Quantum/Eve_Low.mp4" autoPlay muted loop style={{width: "100%"}}  > </video>
        <video src = "Projects/Quantum/Flower_Low.mp4" autoPlay muted loop style={{width: "100%"}}  > </video>
        <video src = "Projects/Quantum/Plant_Low.mp4" autoPlay muted loop style={{width: "100%"}} > </video>
        <video src = "Projects/Quantum/Half_Low.mp4" autoPlay muted loop style={{width: "100%"}}> </video>


    </div>


    
    
    </>
}


export default Quantum