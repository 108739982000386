
import "./About.css"
const About = () => {
    return <>
    <div className="page_container">

      <div className="two_col" style={{height:"100%"}}>
      <div className="about_container">

  <h1>
      Hi, I'm Nikita Shtarkman
  </h1>
    <h2> 
            I am a designer and technologist with a Computer Science background.
            I have worked at companies including OVFX, Material Bank, and Under Armour. 
            I specialize in 3D technology, and am fascinated by the growing world of computer graphics.
    <br/>
    </h2>
    <h1>
      Interests
      <br/>

    </h1>
    <h2>

      <div className="interests">
        3D
      </div>

      <div className="interests">
        Web Design
      </div>
      <div className="interests">
        Graphic Design
      </div>
      <div className="interests">
        Data Visualization
      </div>
      <div className="interests">
        Procedural Modeling
      </div>
      <div className="interests">
        Creative Coding
      </div>

      </h2>
    <h1>
      Education
    </h1>
    <h2>
      <div className="interests">

      Johns Hopkins University<br/>
       Computer Science <br/>
       2016-2019
      </div>

      <div className="interests">
      Parsons School of Design 
      <br/>Design & Technology <br/>2022
      </div>
    </h2>
    <h1>
      Press
    </h1>

    <h2>
      <a href="https://www.collide24.org/interactive/map-of-universe-brice-menard-nikita-shtarkman/" target="_blank">
      <div className="interests">
        collide24
      </div>
      </a>

      <a href="https://www.fastcompany.com/90819258/map-of-the-universe" target="_blank">
      <div className="interests">
        FastCompany
      </div>
      </a>



      <a href="https://www.itsnicethat.com/news/nikita-shtarkman-brice-menard-the-map-of-the-universe-digital-231122" target="_blank">
      <div className="interests">
        It's Nice That
      </div>
      </a>


      
      <a href="https://www.designboom.com/technology/slice-cosmos-interactive-map-scroll-through-universe-12-17-2022/" target="_blank">
      <div className="interests">
        Design Boom
      </div>
      </a>


      <a href="https://www.cbsnews.com/news/you-can-now-explore-the-cosmos-to-the-edge-of-what-can-be-seen-in-new-interactive-map/" target="_blank">
      <div className="interests">
        CBS News
      </div>
      </a>

      
    </h2>
</div>
  <div style={{backgroundColor: "black"}}> 
  
  </div>
  </div>
  </div>


    </>
}

export default About